<template>
    <span v-b-tooltip.hover title="Goals"
        ><project-router-link class="custom-goals-resource" :to="routerLink">
            <i class="icon-trophy pointer-action"> </i
        ></project-router-link>
    </span>
</template>

<script>
export default {
    props: {
        routerLink: {
            required: true,
            type: String,
        },
    },
}
</script>

<style>
.custom-goals-resource i {
    font-weight: 600;
}
.custom-goals-resource:hover {
    text-decoration: none;
    color: #4b4b4c;
}
</style>
