<template>
    <div class="animated fadeIn">
        <b-card class="piplines-list">
            <b-card-header class="card-header-main">
                <b-row class="piplines-list-header">
                    <b-col md="2">
                        <project-router-link to="/collect/source">
                            <b-button :disabled="isViewer" class="text-nowrap" variant="primary">
                                <i class="icon-plus mr-1"></i>Add new Data Collect
                            </b-button>
                        </project-router-link>
                    </b-col>
                </b-row>
            </b-card-header>

            <b-card-body>
                <wit-table
                    mode="server"
                    :search="true"
                    :fields="columns"
                    :request-function="requestFunction"
                    striped
                    paginated
                    hide-per-page-selector
                    ref="collectsTable"
                    :default-per-page="10"
                >
                    <template v-slot:cell(subtype)="{item}">
                        <img
                            v-if="collectTypes[item.subtype]"
                            :src="collectTypes[item.subtype].img"
                            class="collects-list__img"
                        />
                        {{ item.subtype }}
                    </template>

                    <template v-slot:cell(createdAt)="{item}"> {{ formatDate(item.createdAt) }} </template>
                    <template v-slot:cell(updatedAt)="{item}"> {{ formatDate(item.updatedAt) }} </template>

                    <template v-slot:cell(actions)="{item}">
                        <div class="button-row">
                            <DetailsAction :routerLink="detailsMapper(item)" />
                            <RemoveAction :removeResource="showRemoveModal" :resource="item" />
                            <i
                                class="fa fa-external-link-square"
                                @click="openTableReference(item)"
                                v-b-tooltip.hover="'Open BigQuery table'"
                            />
                        </div>
                    </template>
                </wit-table>
            </b-card-body>
        </b-card>

        <RemoveRecordModal
            v-model="showModal"
            :removeElement="removeCollect"
            :recordId="removeRecord ? removeRecord.id : ''"
            @close="removeRecord = null"
        >
            <div slot="error">
                <Feedback :state="collectError.state" :invalid="collectError.message" />
            </div>
        </RemoveRecordModal>

        <AutomaticWorkflowModal v-model="showAutomaticWorkflowModal" :historical="historical" />

        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import moment from 'moment'
import VueNotifications from 'vue-notifications'
import {mapGetters} from 'vuex'

import Feedback from '@/components/Feedback.vue'
import Loading from '@/components/loading.vue'
import ReportList from '@/components/ReportList.vue'
import WitTable from '@/components/WitTable.vue'

import CustomGoalsAction from '@/components/Actions/CustomGoalsAction.vue'
import DetailsAction from '@/components/Actions/DetailsAction.vue'
import PauseOrUnpauseAction from '@/components/Actions/PauseOrUnpauseAction.vue'
import RemoveAction from '@/components/Actions/RemoveAction.vue'
import RemoveRecordModal from '@/components/Modals/RemoveRecordModal.vue'
import WitModal from '@/components/Modals/WitModal.vue'

import {collectTypes} from '@/shared/collectTypes'

export default {
    data() {
        return {
            collectsError: {
                state: true,
                message: '',
            },
            searchUrl: `${process.env.VUE_APP_SEARCH_HOST}/search/collect`,
            apiUrl: `${process.env.VUE_APP_NODE_API_HOST}/collect`,
            columns: ['name', 'type', 'subtype', 'createdAt', 'updatedAt', 'actions'],
            reloadInterval: null,
            showModal: false,
            showWorkflow: false,
            removeRecord: null,
            collectError: {
                state: null,
                message: null,
            },
            showAutomaticWorkflowModal: false,
            historical: false,
        }
    },
    components: {
        Loading,
        ReportList,
        PauseOrUnpauseAction,
        RemoveAction,
        RemoveRecordModal,
        DetailsAction,
        CustomGoalsAction,
        WitModal,
        WitTable,
        Feedback,
        AutomaticWorkflowModal: () => import('@/components/Modals/AutomaticWorkflowModal.vue'),
    },
    notifications: {
        collectUpdated: {
            type: VueNotifications.types.success,
            title: 'Collect has been successfully updated.',
        },
        referenceNotFound: {
            type: VueNotifications.types.error,
            title: 'Could not find table reference',
        },
        formError: {
            type: VueNotifications.types.error,
            timeout: 8000,
            title: 'Could not prepare the form',
        },
    },
    methods: {
        async requestFunction(requestParams) {
            const {query, limit: length, page} = requestParams
            const offset = (page - 1) * length
            const projectId = this.$store.state.project.active.id

            const apiUrl = query ? this.searchUrl : this.apiUrl
            const params = query ? {query, length, offset} : {projectId, ...requestParams}

            try {
                const response = await this.axios.get(apiUrl, {params})

                return {
                    data: response.data.data,
                    count: response.data.metadata.count,
                }
            } catch (error) {
                this.dispatch('error', error)
            }
        },
        detailsMapper(item) {
            if (item.subtype === 'Google Ads') return `collect/googleads/${item.id}`
            else if (item.subtype === 'Criteo Ads') return `collect/criteo/${item.id}`
            else if (item.subtype === 'Facebook Ads') return `collect/facebook/${item.id}`
            else if (item.subtype === 'Google Analytics 4 BigQuery') return `collect/analytics4bq/${item.id}`
            else if (item.subtype === 'Currency Convert') return `collect/currencyConverters/${item.id}`
            else if (item.subtype === 'Custom Stream') return `collect/datastream/${item.id}`
            else if (item.subtype === 'Search Console') return `collect/searchConsole/${item.id}`
            else if (item.subtype === 'RTB House') return `collect/rtb/${item.id}`
            else if (item.subtype === 'Domodi & Allani') return `collect/domodi/${item.id}`
            else if (item.subtype === 'Bing Ads') return `collect/bing/${item.id}`
            else if (item.subtype === 'Custom Data') return `collect/customData/${item.id}`
            else return `collect/${item.subtype.toLowerCase()}/${item.id}`
        },
        deleteMapper(item) {
            if (item.subtype === 'Google Ads') return `collect/googleads/${item.id}`
            else if (item.subtype === 'Criteo Ads') return `collect/criteo/${item.id}`
            else if (item.subtype === 'Facebook Ads') return `collect/facebook/${item.id}`
            else if (item.subtype === 'Google Analytics 4 BigQuery') return `collect/analytics4bq/${item.id}`
            else if (item.subtype === 'Currency Convert') return `collect/currencyConvert/${item.id}`
            else if (item.subtype === 'Custom Stream') return `collect/custom/${item.id}`
            else if (item.subtype === 'Search Console') return `collect/searchConsole/${item.id}`
            else if (item.subtype === 'RTB House') return `collect/RTBHouse/${item.id}`
            else if (item.subtype === 'Domodi & Allani') return `collect/domodi/${item.id}`
            else if (item.subtype === 'Bing Ads') return `collect/bing/${item.id}`
            else if (item.subtype === 'Custom Data') return `collect/customData/${item.id}`
            else return `collect/${item.subtype.toLowerCase().replace(/\s/g, '')}/${item.id}`
        },
        reloadTable() {
            if (!document.hidden && this.$refs.collectsTable) this.$refs.collectsTable.fetchData({silent: true})
        },
        removeCollect() {
            this.collectError.state = null
            this.collectError.message = null

            this.$store.commit('loading/PROCESSING', `Removing the collect...`)
            this.axios
                .delete(`${process.env.VUE_APP_NODE_API_HOST}/${this.deleteMapper(this.removeRecord)}`)
                .then((response) => {
                    this.reloadTable()

                    this.showModal = false
                    this.$store.commit('loading/PROCESSED')
                })
                .catch((exception) => {
                    if (exception.response.status === 409) {
                        const resourcesToDelete = JSON.parse(exception.response.data.data)
                            .map(({name}) => name)
                            .join(', ')
                        this.collectError.message = `We cannot delete this resource. We have detected that it occurs on other resources. First, remove it from these places: ${resourcesToDelete}`
                    } else {
                        this.collectError.message = exception.response.data.data
                    }
                    this.collectError.state = false
                    this.$store.commit('loading/PROCESSED')
                })
        },
        showRemoveModal(collect) {
            this.removeRecord = collect
            this.collectError.state = null
            this.collectError.message = null
            this.showModal = true
        },
        async openTableReference(collect) {
            const {id, projectId} = this.activeProject
            try {
                const response = await this.axios.get(`${this.apiUrl}/tableReference?collectId=${collect.id}`)
                const tableReference = response.data.data

                const href = `https://console.cloud.google.com/bigquery?project=${projectId}&p=${projectId}&d=witcloud_${id.toLowerCase()}&t=${tableReference}&page=table`
                window.open(href, '_blank')
            } catch (e) {
                this.referenceNotFound()
            }
        },
        formatDate(date) {
            return moment(date).format('YYYY-MM-DD HH:mm:ss')
        },
    },
    created() {
        const {collectCreated, collectUpdated, formError, automaticWorkflow, historical} = this.$route.query

        if (collectCreated) {
            this.$router.replace({query: {}})
            this.showWorkflow = true
        }

        if (collectUpdated) {
            this.$router.replace({query: {}})
            this.collectUpdated()
        }

        if (formError) {
            this.$router.replace({query: {}})
            this.formError()
        }

        if (automaticWorkflow) {
            this.$router.replace({query: {}})
            this.showAutomaticWorkflowModal = true
            this.historical = historical === 'true'
        }
    },
    mounted() {
        this.reloadInterval = setInterval(this.reloadTable, 5000)
    },
    beforeDestroy() {
        clearInterval(this.reloadInterval)
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
        }),
        isViewer() {
            return this.activeProject.role == 'Viewer'
        },
        collectTypes() {
            return collectTypes.reduce(
                (acc, curr) => ({
                    ...acc,
                    [curr.subtype]: {...curr, img: curr.img ? require(`@/assets/${curr.img}`) : null},
                }),
                {}
            )
        },
    },
}
</script>

<style lang="scss">
.collects-list__img {
    height: 20px;
}

.button-row {
    i {
        cursor: pointer;
    }
}
</style>
